<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="mb-6">
      <h2 class="font-semibold text-blue-">
        Invoices Report Download
      </h2>
    </div>
    <div class="flex flex-col">
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="w-1/4">
          <div class="grid time-filter">
            <span>From: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startdatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            />
          </div>
          <div class="grid time-filter mt-2">
            <span>To: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.enddatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            />
          </div>
          <div class="flex mt-3">
            <!-- <button
              @click="fetchRecords"
              class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Get Records
            </button> -->
            <button
              @click="fetchRecords"
              type="button"
              class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      isLoading: false,

      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      walletFilter: [],
    };
  },
  created() {
    const d = new Date();

    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };
  },
  computed: {},
  methods: {
    generateCSV() {},
    async fetchRecords() {
      if (this.dateData.startdatetime > this.dateData.enddatetime) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch(
            "FETCH_INVOICES_LOGS_CSV",
            this.dateData
          );
          if (res.status == true) {
            // this.downloadLink = res.data;
            this.loading = false;
            alert(res.message);
            // window.open(res.data, "_blank");
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
  },
};
</script>
