<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="mb-6">
      <h2 class="font-semibold text-blue-">
        Products Report Download
      </h2>
    </div>
    <div class="flex flex-col">
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="w-1/4">
          <div class="grid time-filter">
            <span>From: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startdatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            />
          </div>
          <div class="grid time-filter mt-2">
            <span>To: </span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.enddatetime"
            />
            <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            />
          </div>
          <div class="flex mt-2">
            <button
              @click="fetchRecords"
              class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Downloads
            </button>
            <!-- <button
              @click="generateCSV"
              class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Download
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="alertDiv"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        Please check your email in 5 mins for the download link.
        <br />Please note that it will take longer to generate a download link
        for larger date ranges.
        <button
          @click="alertDiv = false"
          class="
                    float-right
                    uppercase
                    shadow
                    mx-auto
                    hover:bg-red-600
                    bg-red-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-2
                    rounded
                  "
          style="width:inherit"
        >
          Close
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      alertDiv: false,
      dateData: null,
      tim: new Date().getTime(),
      filterBy: [],
      walletFilter: [],
    };
  },
  created() {
    const d = new Date();

    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };

    // this.fetchRecords();
  },
  computed: {},
  methods: {
    generateCSV() {},
    async fetchRecords() {
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;

        let res = await this.$store.dispatch(
          "FETCH_PRODUDCT_LOGS_CSV",
          this.dateData
        );
        this.isLoading = false;
        //const res = this.$store.getters["GET_PAYMENT_LOGS_RES_CSV"];
        if (res.status) {
          // this.alertDiv = true;
          window.open(res.data, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
